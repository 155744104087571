import graphql from 'graphql-tag'

export const GET_ACTIVITY_EVENTS = graphql`
  query ActivityEvents(
    $pageArgs: PaginationArgs
    $filter: ActivityEventFilterOptions
    $sort: SortOrder
  ) {
    activityEvents(filter: $filter, pageArgs: $pageArgs, sort: $sort) {
      totalItems
      items {
        id
        type
        originalData
        timestamp
        playPoints
        totalPoints
        ageCategoryCode
        location {
          organisationId
          organisationName
          locationId
          locationName
          sectionId
          sectionName
          districtId
          districtName
        }
        adminUser {
          firstName
          lastName
        }
        ballColour
        playPoints
        singlesWinPoints
        doublesWinPoints
        totalPoints
        totalWinPoints
        person {
          birthDate
          username
          firstName
          lastName
          sectionName
          districtName
          age
        }
      }
    }
  }
`

export const GET_PERSON_BY_EXTERNAL_ID = graphql`
  query GetPersonByExternalId($externalId: String!) {
    personByExternalId(externalId: $externalId) {
      id: externalId
      personId
      email
      externalId
      status
      tennisId
      extensions {
        description
        name
        value
      }
      section {
        code
        name
      }
      district {
        code
        name
      }
      standardFamilyName
      standardGivenName
      createdAt
      sex
      birthDate
      age
      addresses {
        city
        state
        countryCode
      }
      state
    }
  }
`

export const GET_PERSONS_BY_EXTERNAL_ID = graphql`
  query GetPersonsByExternalId($externalIds: [String!]!) {
    personsByExternalId(externalIds: $externalIds) {
      externalId
      standardFamilyName
      standardGivenName
    }
  }
`
export const GET_PLAYER_PROFILE = graphql`
  query SingleProfile($id: PersonIDInput!) {
    activityProfile(id: $id) {
      id
      points {
        playPoints
        singlesWinPoints
        doublesWinPoints
        totalWinPoints
        totalPoints
        ballColour
        complete
        timestamp
      }
      ballColour
      coachAssignedLevel {
        ballColour
        level
        coachId
      }
      progression {
        daysToAdvance
        pointsToAdvance
        ballColour
      }
      counts {
        type
        ballColour
        count
        totalWinPoints
        totalPoints
        playPoints
        singlesWinPoints
        doublesWinPoints
      }
      person {
        id
        birthDate
        username
        firstName
        lastName
        sectionName
        districtName
        gender
        state
        zipcode
        city
        wheelchairPlayer
        age
      }
    }
  }
`

export const EVALUATE_PROFILE = graphql`
  mutation EvaluateProfile($filter: ActivityTrackerEvaluationFilterOptions) {
    activityProfileEvaluation(filter: $filter)
  }
`

export const GET_PLAYERS = graphql`
  query ActivityProfiles(
    $pageArgs: PaginationArgs
    $filter: ActivityTrackerProfileFilterOptions
    $sort: SortOrder
  ) {
    activityProfiles(filter: $filter, pageArgs: $pageArgs, sort: $sort) {
      items {
        id
        points {
          playPoints
          ballColour
          complete
          timestamp
        }
        ballColour
        progression {
          daysToAdvance
          pointsToAdvance
          ballColour
        }
        counts {
          type
          ballColour
          count
        }
        person {
          id
          birthDate
          username
          firstName
          lastName
          sectionName
          districtName
          gender
          state
          zipcode
          city
          wheelchairPlayer
          age
        }
      }
      totalItems
    }
  }
`

export const GET_PLAYERS_BY_UAID = graphql`
  query GetPlayersByUAID($ids: [ID!]!) {
    findUSTAMembersByID(ids: $ids) {
      id
      birthDate
      username
      firstName
      lastName
      sectionName
      districtName
      gender
      state
      zipcode
      city
      wheelchairPlayer
      age
    }
  }
`

export const SEARCH_PLAYERS = graphql`
  query SearchPeople(
    $pageArgs: PaginationArgs
    $filter: PersonFilterOptionsInput
    $sort: SortOrder
  ) {
    searchPeople(pageArgs: $pageArgs, filter: $filter, sort: $sort) {
      results {
        id: externalId
        personId
        externalId
        standardFamilyName
        standardGivenName
        age
        birthDate
        district {
          name
        }
        section {
          name
        }
        addresses {
          city
        }
        state
        sex
        groups {
          groupId
          groupName
        }
      }
      total
    }
  }
`

export const SEARCH_GROUP = graphql`
  query findGroup($pageArgs: PaginationArgs, $filter: GroupFilterOptionsInput, $sort: SortOrder) {
    findGroup(pageArgs: $pageArgs, filter: $filter, sort: $sort) {
      results {
        groupId
        groupName
      }
      total
    }
  }
`

export const ADD_ACTIVITY_POINTS = graphql`
  mutation AddActivityPoints($id: PersonIDInput!, $input: ActivityManualAdjustmentInput!) {
    addActivityManualAdjustment(id: $id, input: $input) {
      id
      timestamp
      ballColour
      playPoints
      person {
        birthDate
        username
        firstName
        lastName
        sectionName
        districtName
      }
    }
  }
`

//SUSPENSIONS
export const LIST_SUSPENSIONS = graphql`
  query ListSuspensions {
    listSuspensions {
      id
      uaid
      startDate
      endDate
      comment
    }
  }
`

export const LIST_SUSPENSIONS_FOR_PLAYER = graphql`
  query ListSuspensionsForPlayer($uaid: String!) {
    listSuspensionsForPlayer(uaid: $uaid) {
      id
      uaid
      startDate
      endDate
      comment
      firstName
      lastName
      dateOfBirth
      district
      section
    }
  }
`

export const CREATE_SUSPENSION = graphql`
  mutation CreateSuspension($input: SuspensionCreateInput!) {
    createSuspension(suspensionCreateInput: $input) {
      id
      uaid
      startDate
      endDate
      comment
      firstName
      lastName
      dateOfBirth
      district
      section
    }
  }
`

export const UPDATE_SUSPENSION = graphql`
  mutation UpdateSuspension($input: SuspensionUpdateInput!, $id: String!) {
    updateSuspension(suspensionUpdateInput: $input, id: $id) {
      id
      uaid
      startDate
      endDate
      comment
      firstName
      lastName
      dateOfBirth
      district
      section
    }
  }
`

export const DELETE_SUSPENSION = graphql`
  mutation DeleteSuspension($id: String!) {
    deleteSuspension(id: $id) {
      id
      uaid
    }
  }
`

//SUSPENSION CODES
export const GET_SUSPENSION_CODES = graphql`
  query ListSuspensionCodes {
    listSuspensionCodes {
      code
      shortDescription
      longDescription
      points
    }
  }
`

export const GET_SUSPENSION_CODE = graphql`
  query GetSuspensionCode($code: String!) {
    getSuspensionCode(code: $code) {
      code
      shortDescription
      longDescription
      points
    }
  }
`

export const UPDATE_SUSPENSION_CODE = graphql`
  mutation UpdateSuspensionCode($code: String!, $input: SuspensionCodeUpdateInput!) {
    updateSuspensionCode(
      code: $code # the suspension code to update
      suspensionCodeUpdateInput: $input
    ) {
      code
      shortDescription
      longDescription
      points
    }
  }
`

export const ADD_SUSPENSION_CODE = graphql`
  mutation CreateSuspensionCode($input: SuspensionCodeCreateInput!) {
    createSuspensionCode(suspensionCodeCreateInput: $input) {
      code
      shortDescription
      longDescription
      points
    }
  }
`

//VIOLATIONS
export const LIST_PLAYER_VIOLATIONS = graphql`
  query ListViolationsForPlayer($uaid: String!, $expired: Boolean, $suspensionId: String) {
    listViolationsForPlayer(uaid: $uaid, expired: $expired, suspensionId: $suspensionId) {
      id
      uaid
      suspensionCode {
        code
        points
      }
      expiryDate
      createdAt
      violationDate
      comment
      tournamentId
      tournamentName
      tournamentStartDate
      tournamentEndDate
      tournamentDirectorPhone
      tournamentDirectorComment
      isTournamentDesk
      refereeFirstName
      refereeLastName
      refereeUaid
      refereePhone
      eventName
      organizationName
      roundName
      matchUpPlayers
      matchUpResult
      reporterFirstName
      reporterLastName
    }
  }
`

export const GET_PLAYER_SUSPENSION_STATUS = graphql`
  query GetPlayerSuspensionStatus($uaid: String!) {
    getPlayerSuspensionStatus(uaid: $uaid) {
      points
      status
      suspension {
        id
        comment
        startDate
        endDate
      }
    }
  }
`

export const LIST_ALL_PLAYER_VIOLATIONS = graphql`
  query ListAllPlayerViolations(
    $pageArgs: PaginationArgs
    $filterOptions: ListPlayersFilter
    $sort: SortOrder
  ) {
    listAllPlayerViolations(pageArgs: $pageArgs, filterOptions: $filterOptions, sort: $sort) {
      totalPlayers
      players {
        points
        status
        uaid
        firstName
        lastName
        dateOfBirth
        district
        section
        category
      }
    }
  }
`

//ACTIONS
export const LIST_PLAYER_ACTIONS = graphql`
  query ListPlayerActions($uaid: String!, $pageArgs: PaginationArgs) {
    listPlayerActions(uaid: $uaid, pageArgs: $pageArgs) {
      totalActions
      actions {
        uaid
        type
        createdAt
        user {
          email
          firstName
          lastName
        }
        objectDeleted
        object {
          __typename
          ... on SuspensionsBaseObjectType {
            id
            comment
          }
        }
      }
      totalActions
    }
  }
`

export const UPDATE_SUSPENSION_EMAIL = graphql`
  mutation UpdateSuspensionEmail($id: String!, $input: SuspensionEmailUpdateInput!) {
    updateSuspensionEmail(id: $id, suspensionEmailUpdateInput: $input) {
      id
      comment
      type
      uaid
    }
  }
`

export const CREATE_SUSPENSION_EMAIL = graphql`
  mutation CreateSuspensionEmail($input: SuspensionEmailCreateInput!) {
    createSuspensionEmail(suspensionCreateInput: $input) {
      id
      comment
      type
      uaid
    }
  }
`

export const GET_SUSPENSION = graphql`
  query GetPlayerSuspension($id: String!) {
    getSuspension(id: $id) {
      id
      comment
      uaid
      startDate
      endDate
      firstName
      lastName
      dateOfBirth
      district
      section
    }
  }
`
