// extracted by mini-css-extract-plugin
export var bodyBase = "edit-suspension-point-module--body-base--Of3V6 edit-suspension-point-module--site-font--e2jIB";
export var bodyLarge = "edit-suspension-point-module--body-large--imn4B edit-suspension-point-module--body-base--Of3V6 edit-suspension-point-module--site-font--e2jIB";
export var bodyLargeBold = "edit-suspension-point-module--body-large-bold--c+BfI edit-suspension-point-module--body-base--Of3V6 edit-suspension-point-module--site-font--e2jIB";
export var bodyRegular = "edit-suspension-point-module--body-regular--5Zq5- edit-suspension-point-module--body-base--Of3V6 edit-suspension-point-module--site-font--e2jIB";
export var bodyRegularBold = "edit-suspension-point-module--body-regular-bold--aQqqe edit-suspension-point-module--body-base--Of3V6 edit-suspension-point-module--site-font--e2jIB";
export var bodySmall = "edit-suspension-point-module--body-small--MvTr4 edit-suspension-point-module--body-base--Of3V6 edit-suspension-point-module--site-font--e2jIB";
export var bodySmallBold = "edit-suspension-point-module--body-small-bold--Q9kdY edit-suspension-point-module--body-base--Of3V6 edit-suspension-point-module--site-font--e2jIB";
export var borderTop = "edit-suspension-point-module--border-top--dI8a2";
export var breakWordContainer = "edit-suspension-point-module--break-word-container--EHn8l";
export var buttonIconBase = "edit-suspension-point-module--button-icon-base--xOV-Q";
export var clickLink = "edit-suspension-point-module--click-link--o2PMn";
export var datepickerContainer = "edit-suspension-point-module--datepicker-container--9MrHU";
export var dropdownBase = "edit-suspension-point-module--dropdown-base--VbD1q";
export var dropdownSelectBase = "edit-suspension-point-module--dropdown-select-base--fNqL2 edit-suspension-point-module--text-input--gKp+2";
export var flexCol = "edit-suspension-point-module--flex-col--tkMqa";
export var formErrorMessage = "edit-suspension-point-module--form-error-message--iOpzZ";
export var h3 = "edit-suspension-point-module--h3--kFZlt";
export var h4 = "edit-suspension-point-module--h4--c849e";
export var hoverLink = "edit-suspension-point-module--hover-link--3g7yF";
export var hoverRow = "edit-suspension-point-module--hover-row--+rR1k";
export var icon = "edit-suspension-point-module--icon--m1BnJ";
export var infoLabelOverride = "edit-suspension-point-module--info-label-override--EByiQ";
export var membershipContainer = "edit-suspension-point-module--membership-container--DDJr- edit-suspension-point-module--flex-col--tkMqa edit-suspension-point-module--primary-border--Cys2D";
export var membershipHeader = "edit-suspension-point-module--membership-header--y6pcW";
export var membershipHeading = "edit-suspension-point-module--membership-heading--wyidI";
export var membershipLabel = "edit-suspension-point-module--membership-label--HFxAr";
export var moreFiltersBorderClass = "edit-suspension-point-module--more-filters-border-class--RIt0f";
export var padding = "edit-suspension-point-module--padding--ZHuFY";
export var pageBg = "edit-suspension-point-module--page-bg--egMuf";
export var pointer = "edit-suspension-point-module--pointer--7P08X";
export var points = "edit-suspension-point-module--points--D9RNP";
export var pointsField = "edit-suspension-point-module--points-field--+SELs edit-suspension-point-module--primary-border--Cys2D";
export var primaryBorder = "edit-suspension-point-module--primary-border--Cys2D";
export var shadowBoxLight = "edit-suspension-point-module--shadow-box-light--RQYMw";
export var siteFont = "edit-suspension-point-module--site-font--e2jIB";
export var slideDownAndFade = "edit-suspension-point-module--slideDownAndFade--LcT6d";
export var slideLeftAndFade = "edit-suspension-point-module--slideLeftAndFade--FP51E";
export var slideRightAndFade = "edit-suspension-point-module--slideRightAndFade--H+NIX";
export var slideUpAndFade = "edit-suspension-point-module--slideUpAndFade--YM7j7";
export var statusDecoration = "edit-suspension-point-module--status-decoration--OZgwN";
export var suspensionPointEvent = "edit-suspension-point-module--suspension-point-event--C4JRu edit-suspension-point-module--primary-border--Cys2D";
export var tdComments = "edit-suspension-point-module--td-comments--yiWgs";
export var textAreaContainer = "edit-suspension-point-module--text-area-container--SbMtC";
export var textInput = "edit-suspension-point-module--text-input--gKp+2";
export var textInverted = "edit-suspension-point-module--text-inverted--JHl3Y";
export var textMediumDark = "edit-suspension-point-module--text-medium-dark--0XQ8W";
export var tooltipFont = "edit-suspension-point-module--tooltipFont--ss27j";
export var unstyledButton = "edit-suspension-point-module--unstyled-button--LjQ8C";