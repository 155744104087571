import graphql from 'graphql-tag'

export const GET_PLAYERS = graphql`
  query PersonsByPersonId($personIds: [String!]!) {
    personsByPersonId(personIds: $personIds) {
      personId
      standardFamilyName
      standardGivenName
      groups {
        groupId
        groupName
        role
        year
      }
    }
  }
`

export const GET_RANK_LISTS = graphql`
  query td_RankLists(
    $pageArgs: td_PaginationArgs
    $sortOrder: td_SortOrder
    $filter: td_RankListFilter
  ) {
    td_rankLists(
      filter: $filter
      pageArgs: $pageArgs
      sort: $sortOrder
    ) {
      totalItems
      items {
        id
        name
        publishCount
        createdAt
        publishDate
        plannedPublishDate
        calculatedDate
        divisionType
        matchFormat
        matchFormatType
        gender
        listType
        visible
        area
        updatedBy
        listMethod
        pass
        dateRange {
          start
          end
        }
        rankingItems {
          totalItems
          items {
            rank
            points {
              total
            }
            participants {
              itemId
              name
              birthDate
              participantType
            }
          }
        }
      }
    }
  }
`


export const GET_RANK_LIST_ITEM = graphql`
  query td_RankList($id: String!) {
    td_rankList(id: $id) {
      id
      name
      publishCount
      createdAt
      publishDate
      calculatedDate
      divisionType
      matchFormat
      matchFormatType
      gender
      listType
      visible
      area
      publishCount
      listMethod
      pass
      dateRange {
        start
        end
      }
      rankingItems {
        totalItems
        items {
          rank
          points {
            total
          }
          votes
          participants {
            itemId
            name
            birthDate
            participantType
          }
        }
      }
    }
  }
`

export const CREATE_RANK_LIST = graphql`
  mutation td_CreateRankList($input: td_CreateRankListInput!) {
    td_createRankList(input: $input) {
      id
      name
      publishCount
      createdAt
      publishDate
      calculatedDate
      divisionType
      matchFormat
      matchFormatType
      gender
      listType
      visible
      area
      publishCount
      listMethod
      dateRange {
        start
        end
      }
      rankingItems {
        totalItems
        items {
          rank
          points {
            total
          }
          votes
          participants {
            itemId
            name
            birthDate
            participantType
          }
        }
      }
    }
  }
`

export const UPDATE_RANK_LIST = graphql`
  mutation td_UpdateRankList($id: ID!, $input: td_UpdateRankListInput!) {
    td_updateRankList(id: $id, input: $input) {
      __typename
      id
      name
      publishCount
      createdAt
      publishDate
      calculatedDate
      divisionType
      matchFormat
      matchFormatType
      gender
      listType
      visible
      area
      publishCount
      listMethod
    }
  }
`

export const DELETE_RANK_LIST = graphql`
  mutation td_DeleteRankList($id: ID!) {
    deleteRankList(id: $id)
  }
`

export const PUBLISH_RANK_LIST = graphql`
  mutation td_publishRankList($ids:[ID!]!) {
    td_publishRankLists(ids: $ids)
  }
`

export const UNPUBLISH_RANK_LIST = graphql`
  mutation td_unpublishRankList($ids:[ID!]!) {
    td_unpublishRankLists(ids: $ids)
  }
`
