import React from 'react'
import { usePrivateRoute } from 'src/utils/auth'
import { Router, RouteComponentProps } from '@reach/router'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import EditSuspensionPoint from 'src/components/edit-suspension-point/edit-suspension-point'

const EditViolationPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/players/:id/suspensions/violations">
      <EditViolationRoute path="/:violationId" />
    </Router>
  )
}

interface EditViolationRouteProps extends RouteComponentProps {
  id?: string
  violationId?: string
}

const EditViolationRoute: React.FC<EditViolationRouteProps> = ({ id, violationId }) => {
  return (
    <Layout>
      <SEO title="Edit violation" />
      <EditSuspensionPoint id={id} violationId={violationId} />
    </Layout>
  )
}

export default EditViolationPage
