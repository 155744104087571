/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum RankListAreaEnum {
  National = "National",
  Regional = "Regional",
}

export enum RankListStatusEnum {
  Approved = "Approved",
  Hidden = "Hidden",
  Published = "Published",
}

export interface DateRangeFilterInput {
  startDate: any;
  endDate: any;
}

export interface PaginationArgs {
  limit?: number | null;
  skip?: number | null;
}

export interface RankListFilterInput {
  providerId?: string | null;
  dateRange?: DateRangeFilterInput | null;
  divisions?: string[] | null;
}

export interface RankListInput {
  providerId: string;
  startDate: any;
  endDate?: any | null;
  division: string;
  name?: string | null;
  gender?: string | null;
  discipline?: string | null;
  rankingType?: string | null;
  wheelchairClass?: string | null;
  status?: RankListStatusEnum | null;
  area?: RankListAreaEnum | null;
  maxPlayers?: number | null;
  updatedBy?: string | null;
  totalEntries?: number | null;
  rankingItems: RankListItemInput[];
  updatedAt?: any | null;
  createdAt?: any | null;
}

export interface RankListItemInput {
  personIds: string[];
  rank: number;
  team?: string | null;
  type?: string | null;
  points?: number | null;
  votes?: number | null;
}

export interface RankListSortingInput {
  sortBy?: string | null;
  sortDirection?: number | null;
}

export interface UpdateRankListInput {
  providerId?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  division?: string | null;
  name?: string | null;
  gender?: string | null;
  discipline?: string | null;
  rankingType?: string | null;
  status?: RankListStatusEnum | null;
  area?: RankListAreaEnum | null;
  maxPlayers?: number | null;
  updatedBy?: string | null;
  totalEntries?: number | null;
  wheelchairClass?: string | null;
  rankingItems?: (RankListItemInput | null)[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
