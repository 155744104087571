// import { MUIDialog, MUIDialogContent, MUIDialogTitle } from '../dialog/dialog'
import {
  MUIDialog as Dialog,
  MUIDialogActions as DialogActions,
  MUIDialogContent as DialogContent,
  MUIDialogTitle as DialogTitle
} from '../dialog/dialog'
import React from 'react'

interface CustomDialogProps {
  onClose: () => void
  open: boolean
  title: string
  content?: React.ReactNode
  actions?: React.ReactNode
  hideX?: boolean
  overridePosition?: any
  titleStyle?: string
  visibleOverflow?: boolean
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  onClose,
  open,
  content,
  actions,
  title,
  hideX = false,
  overridePosition,
  titleStyle,
  visibleOverflow
}: CustomDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      overridePosition={overridePosition}
      visibleOverflow={visibleOverflow}
    >
      <DialogTitle
        onClose={onClose}
        title={title}
        hideX={hideX}
        titleStyle={titleStyle}
      ></DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  )
}

export default CustomDialog
